.App {
    background-color: #efefed;
    color: #09479c;
    background-image: url('./background.png');
    background-attachment: fixed;
    background-position: 45% 80%;
}

.menu {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 8px;
    background-color: #131a2e;
    background-image: linear-gradient(to left bottom, #09479c, #00539f, #005fa0, #0b69a0, #29739e);
    z-index: 100;
}

.menu>button {
    background: transparent;
    border: none;
    outline: none;
    color: white;
}

.menu>div {
    margin: 0 16px;
    color: white;
}

.menu>a {
    position: absolute;
    right: 8px;
    font-size: 16px;
    font-weight: bold;
    color: white;
}

.top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 85vh;
}

.top>img {
    width: 450px;
    max-width: 85%;
    margin-top: 50px;
}

.top .legend {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    font-size: 22px;
}

.detail {
    font-family: 'Lato';
    font-weight: 300;
    width: 80%;
    text-align: center;
    margin-top: 32px;
    width: 450px;
    max-width: 85%;
}

.carousel {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.carousel-inner {
    display: flex;
}

.realisations {
    width: 100%;
}

.realisations img {
    object-fit: cover;
    width: auto;
    height: 400px;
}

.carousel-item {
    height: 400px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2309479c' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2309479c' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.realisations h2 {
    font-family: 'Lato';
    font-size: 28px;
    margin-left: 24px;
    margin-bottom: 24px;
    font-weight: 400;
    text-align: center;
}

.contact-form {
    padding-bottom: 66px;
}

.contact-form h2 {
    font-family: 'Lato';
    font-size: 28px;
    margin: 66px 0 40px 24px;
    font-weight: 400;
    text-align: center;
}

.contact-form .form-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 8px;
}

input,
textarea,
.file {
    background: #07275590;
    color: white;
    margin-bottom: 16px;
    border-radius: 2px;
    outline: none;
    padding: 8px;
    /* border-image: linear-gradient(to right, #a93852, #923d6b) 100% 1; */
    /* border-top: 0; */
    border: 0;
    width: 320px;
}

input::placeholder,
textarea::placeholder {
    color: #FFFFFF90;
}

.btnn {
    flex: 1 1 auto;
    margin: 10px;
    padding: 8px 16px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 2px;
    background-image: linear-gradient(to left bottom, #09479c, #00539f, #005fa0, #0b69a0, #29739e);
    border: none;
}

.contact {
    background-color: #a93852;
    border-color: #a93852;
}

.gmap_canvas {
    width: 400px;
    height: 500px;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    margin-top: 20px;
}

.gmap_canvas iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.contact-container {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
}

.mail-sent {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.mail-sent>div {
    background-color: white;
    height: 400px;
    padding: 60px;
    max-width: 70%;
    text-align: center;
    font-size: 20px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mail-sent>div>div>div:first {
    margin-bottom: 20px;
}

.error {
    color: #FE5B5F;
    font-weight: 500;
}

.infos {
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.infos>div:first-child {
    font-size: 22px;
}

.infos>a {
    color: #29739e;
    font-weight: 700;
    text-decoration: none;
}

.footer {
    text-align: center;
    margin-top: 64px;
}

@media screen and (max-width: 900px) {
    .contact-container {
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 700px) {
    .top .legend {
        font-size: 22px;
    }
    .top img {
        max-width: 70%;
        margin: 0;
    }
    .gmap_canvas {
        width: 304px;
        height: 320px;
    }
    .menu {
        display: none;
    }
}